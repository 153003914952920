body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  min-height: 100vh;
  background-color: var(--color-background-second);
}

.sideBarlogo {
  background-color: #f8f9fa;
  border-radius: 2px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.client-navbar img {
  width: 82px;
}

.client-form input:focus,
.client-form textarea:focus,
.client-form select:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

button:focus {
  /* border-color: white; */

  outline: none;
}

.client-navbar {
  padding: 14px 0;
  background-color: var(--color-background);
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 100vh; */
}

hr {
  margin-top: 16px;
  margin-bottom: 16px;
  border-top: 1px solid #084298;
  opacity: 0.25;
}

.client-form {
  background-color: var(--color-background-second);
  height: 100%;
  padding-bottom: 5px;
}

.client-form-parent {
  width: 77%;
  background-color: var(--color-background);
  height: 100%;

  /* display: flex; */
  margin: 0 auto;
  margin-top: 20px;
  color: #182a52ff;
  padding: 15px !important;
  border-radius: 6px;
  box-shadow: 0px 1px 4px #1522322e;
}

.bold {
  font-weight: 700;
}

.client-notification {
  position: relative;
  padding: 8px 5px;
  margin-top: 15px !important;
  color: #084298;
  background-color: #cfe2ff;
  border: 1px solid transparent;
  border-radius: 6px;
}

.cform h2 {
  margin-top: 10px;
}

.client-form-row {
  display: flex;
  width: 100%;
  /* gap: 20px; */
  /* background-color: #084298; */
  /* justify-content: ; */
}

.info-column {
  /* background-color: #212529; */
  width: 100%;
}

.client-form-row input,
.client-form select,
.client-form textarea {
  margin: 10px 0;
  width: 87%;
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.client-form-select {
  margin: 10px 0;
  /* display: block; */
  /* width: 100%; */
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: var(--color-background);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.client-accordion {
  color: #212529;
  background-color: var(--color-background);
  /* transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,border-radius 0.15s ease; */
  border-color: #dee2e6;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.375rem;
  /* --bs-accordion-inner-border-radius: calc(0.375rem - 1px); */
  /* --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-body-color%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); */
  /* --bs-accordion-active-color: #0c63e4;
  --bs-accordion-active-bg: #e7f1ff; */
}

.client-accordion h2 {
  margin-top: 0;
}

.client-accordion-header {
  margin: 0px;
  /* overflow: hidden; */
}

.arrow {
  display: flex;
  /* flex-shrink: 0; */
  width: 1.25rem !important;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  /* background-color: #000; */
  /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-body-color%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); */
  /* background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition); */
}

.client-accordion-item {
  max-width: 100%;
  border-bottom: 1px solid #dee2e6;
  /* border-radius: 0.25rem; */
  /* border-top-right-radius: 0.25rem; */
  /* overflow: hidden; */
  /* padding-left: 1.25rem; */
}

.client-accordion-button {
  /* position: relative; */
  display: flex;
  /* align-items: center;  */
  justify-content: space-between;
  width: 100%;
  padding: 1.5rem 1.25rem 1rem 2.5rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 0.375rem;

  /* overflow: hidden  ; */
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}

.client-accordion-button:focus {
  color: #212529;
  background-color: var(--color-background);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.client-accordion-body {
  padding: 1rem;
}

.arrow-rotate {
  transform: rotateX(180deg);
}

/* .form-label{
  position: relative;
} */

.client-tooltip {
  position: relative;
  display: inline-block;
  /*border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.client-tooltip .client-tooltiptext {
  visibility: hidden;
  width: 220px;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -110px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.client-tooltip:hover .client-tooltiptext {
  visibility: visible;
}

.client-tooltip .client-tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%;
  /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.client-badge {
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  color: #fff;
  border-radius: 50rem;
  display: inline-flex;
  align-items: center;
  gap: 3px;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

.alert-blue {
  padding: 10px;
  margin: 15px 0;
  color: #084298;
  background-color: var(--color-background-client-alert);
  border: 1px solid #b6d4fe;
  border-radius: 0.375rem;
}

.alert-blue h5 {
  /* padding-bottom: 5px; */
  font-size: 1.1rem;
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
}

.client-form p {
  color: var(--color-text);
  font-size: 12px;
  margin-bottom: 1rem;
}

.client-file-input p,
.warning {
  color: #000 !important;
}

.client-form li {
  font-size: 12px;
  color: #084298;
}

.col-2-3rd {
  width: 66.66666667%;
}

.col-1-3rd {
  width: 33.33333333%;
}

.clientMessage {
  padding: 10px;
}

.client-form h4 {
  margin: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
}

.document-btn {
  display: flex;
  justify-content: flex-end;
}

.document-btn button {
  color: #fff;
  background-color: #48cae4;
  border-color: #48cae4;
  font-size: 1rem;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-style: solid;
  border-radius: 0.375rem;
  /* box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); */
  /* text-align: center; */
}

.document-btn button:hover {
  /* background-color: #0b5ed7; */
  filter: brightness(0.93);
  cursor: pointer;
}

/* .document-btn button:focus {
  box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);

} */

.client-card {
  padding: 16px;
  color: var(--color-text);
  background-color: var(--color-background);
  border: 1px solid var(--color-background-client-border);
  border-radius: 0.375rem;
}

.text-red {
  color: #ff0000;
  font-weight: 700;
  font-size: 12px;
}

.warning {
  text-align: center;
  font-size: 10px;
  padding: 5px;
  color: red;
}

/* .card  */

/* .form-control{
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: .375rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
} */

/*---------------------------------------------- choose file, input file */

.client-file-input input[type="file"] {
  /* background-color: #ced4da; */
  padding: 0;
  cursor: pointer;
}

input[type="file"]::file-selector-button {
  border-width: 0;
  background-color: #e8ecef;
  /* height: 100%; */
  padding: 12px;
  margin-right: 14px;
  cursor: pointer;
}

.client-file-input:hover>input[type="file"]::file-selector-button {
  filter: brightness(0.93);
}

/* ----------------------------------------------- */

/* .portal-btn {} */

.client-btn:hover {
  background-color: #34dbb4;
  border-color: #34dbb4;
  filter: brightness(0.93);
}

button:hover {
  cursor: pointer;
}

.footertext {
  /* color: #000000 !important; */
  text-align: center;
  font-size: 10px;
  padding: 5px 0px 0px 0px;
  max-height: 5vh;
  margin-bottom: 0 !important;
  /* margin-top: 10px; */
}

/* Signup */

.form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  margin: auto;
  /* padding-top: 40px; */
}

.form-signin {
  width: 100%;
  max-width: 400px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.form-signin .error {
  font-size: 12px;
  margin-bottom: 3px;
}

#hover-btn #icon {
  display: none;
}

#hover-btn #icon i {
  cursor: pointer;
}

#hover-btn:hover #icon {
  display: block;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100000;
}

/*------------Admin Navbar ------------*/
.admin-btn {
  /* color: #fff; */
  color: #000000;
  /* background-color: #48cae4 !important; */
  background-color: #33DBB4 !important;
  /* border-color: #48cae4 !important; */
  border-color: #33DBB4 !important;
  font-size: 1rem;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-style: solid;
  border-radius: 0.375rem;
  text-decoration: none;
}

.admin-btn:hover {
  filter: brightness(0.93);
  border-color: #33DBB4 !important;
  background-color: #33DBB4 !important;
  color: #000000;

}

.nav-item {
  padding: 2px !important;
  margin: 2px !important;
}

.client-btn {
  color: #fff;
  background-color: #34dbb4;
  border-color: #34dbb4;
  font-size: 1rem;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-style: solid;
  border-radius: 0.375rem;
  text-decoration: none;
  vertical-align: middle;
}

.btn-light {
  /* background-color: #caf0f8; */
  background-color: #445775;
  border-color: #445775;
  color: #fff;
}

.btn-light:hover {
  /* color: #0096c7; */
  color: #fff;
  background-color: #445775;
  border-color: #445775;
  filter: brightness(0.93);
  /* background-color: #d1eaf0 !important; */
  /* border-color: #d1eaf0 !important; */
}

/*----------------event portal------------------*/

.admin-body {
  max-height: calc(100vh-117px);
}

.appcountainer {
  background: var(--color-background);
  /* color: #182a52ff;
  padding: 15px !important;
  border-radius: 6px;
  box-shadow: 0px 1px 4px #1522322e;
  max-height: calc(100vh - 108px); */
  /* height: calc(100vh - 108px); */
  /* overflow: hidden; */
  /* height: 90vh; */
  /* max-height: 83vh; */

  color: #182a52ff;
  padding: 15px !important;
  border-radius: 6px;
  box-shadow: 0px 1px 4px #1522322e;
  max-height: 88vh;
  overflow: hidden;
}

.editUsers {
  background: var(--color-background);
  /* color: #182a52ff;
  padding: 15px !important;
  border-radius: 6px;
  box-shadow: 0px 1px 4px #1522322e;
  max-height: calc(100vh - 108px); */
  /* height: calc(100vh - 108px); */
  /* overflow: hidden; */
  /* height: 90vh; */
  /* max-height: 83vh; */

  color: #182a52ff;
  padding: 15px !important;
  border-radius: 6px;
  box-shadow: 0px 1px 4px #1522322e;
  /* max-height: 88vh; */
  overflow: hidden;
}

.appcountainer2 {
  background: var(--color-background);
  color: #182a52ff;
  padding: 15px !important;
  border-radius: 6px;
  height: calc(100vh - 108px);
  box-shadow: 0px 1px 4px #1522322e;
  overflow: auto !important;
  /* max-height: 88vh; */
  /* overflow: hidden; */
  /* max-height: 83vh; */
}

.eventsContainer {
  background: var(--color-background);
  color: #182a52ff;
  padding: 15px !important;
  border-radius: 6px;
  height: calc(100vh - 108px);
  box-shadow: 0px 1px 4px #1522322e;
  /* overflow: auto !important; */
  /* max-height: 88vh; */
  /* overflow: hidden; */
  /* max-height: 83vh; */
}

.container {
  max-width: 100% !important;
}

/* 
.maincont {
  padding: 0px 15px !important;
} */

.result-box {
  height: 55px;
}

.dark-mode>.form-control-plaintext~label::after,
.dark-mode>.form-control:focus~label::after,
.dark-mode>.form-control:not(:placeholder-shown)~label::after,
.dark-mode>.form-select~label::after {
  background-color: transparent;
  /* Other dark mode styles for ::after pseudo-element */
}

.dark-mode::placeholder {
  border-color: #dee2e6;
  background: transparent;
  color: #ffffff;
}

.bodysectab {
  /* max-height: 65vh;
  min-height: 65vh; 
  height: 65vh; */
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 5px;
  /* max-height: 60vh; */
  height: calc(88vh - 225px);
  max-height: 88vh;
  /* max-height: 60vh;
  min-height: 60vh; */

  /* height: 73vh;
  max-height: 73vh;
  min-height: 73vh; */
}

.bodysecsearch {
  max-height: 62vh;
  min-height: 62vh;
  height: 62vh;
  overflow: scroll;
  overflow-x: hidden;
  /* 
  max-height: 70vh;
    min-height: 70vh;
    height: 70vh;
    overflow: scroll; */
}

.footersec {
  /* max-height: 5vh; */
  min-height: 5vh;
  /* height: 5vh; */
  /* margin-top: 8px; */
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 0.25rem rgba(75, 151, 199);
}

.App p {
  margin-bottom: 0;
}

.smalltext {
  font-size: 10px;
}

.form-select:hover,
.form-control:hover {
  border-color: hsl(0, 0%, 70%);
}

.customerEmailDrop {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 0.375rem;
  border-color: #dee2e6;
  padding: 10px 8px 10px 8px;
}

.custom-select {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m2 5 6 6 6-6'/%3e%3c/svg%3e") no-repeat right 0.85rem center/12px 12px !important;
}

/* For the closed state */
.accordion-buttonDark.collapsed::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-buttonDark:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-buttonDark:focus {
  box-shadow: 0 0 0 0.1rem #34dbb4 !important;
}

.accordion-buttonLight:focus {
  box-shadow: 0 0 0 0.1rem #48cae4 !important;
}

.custom-date::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

/* .modal-header {

  border-bottom: 1px solid #495057;
}
.modal-footer {

  border-top: 1px solid #495057;
} */

.darkthead {
  color: #34dbb4 !important;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 2rem; */
  margin-bottom: 0;
  user-select: none;
}

.pagination .pagination-link {
  border: none;
  font-size: 16px;
  color: gray;
  padding: 4px 10px;

  border: 1px solid #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination .page-item {
  cursor: pointer;
  margin: 0px 5px;
}

.pagination .page-item.disabled .pagination-link {
  width: 40px;
  height: 40px;
  background: #e0e0e0;
  border-radius: 100px;
}

.pagination .page-item.last-page .pagination-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: #48cae4;
  border-radius: 100px;
  color: white;
  font-size: 1.1rem;
}

.pagination .page-item2.last-page2 .pagination-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;

  border: none;
  color: #2bcfa9;
  font-weight: 600 !important;
  font-size: 1.1rem;
}

.pagination .page-item2.first-page2 .pagination-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;

  border: none;
  color: #2bcfa9;
  font-weight: 600 !important;
  font-size: 1.1rem;
}

.pagination .page-item.first-page .pagination-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: #48cae4;
  border-radius: 100px;
  color: white;
  font-size: 1.1rem;
}

.pagination .page-item .disabled {
  background: #e0e0e0 !important;
}

.pagination .page-item .pagination-link i {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.pagination .page-item .pagination-link {
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-decoration: none;
  width: 35px;
  height: 35px;
}

.pagination .active-page {
  font-size: 18px;
  color: black !important;

  background-color: #e9ecef;
}

.pagination .active-page2 {
  font-size: 18px;
  color: white !important;

  background-color: #2bcfa9;
}

.pagination .page-item.active .pagination-link {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: rgba(100, 116, 139, 0.3);
  background: transparent;
}

/* loader */
#loading {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  background-color: var(--color-background-second);
}

#load {
  position: absolute;
  width: 800px;
  height: 36px;
  left: 40%;
  top: 40%;
  margin-left: -300px;
  overflow: visible;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

.letter {
  position: absolute;
  width: 20px;
  height: 36px;
  opacity: 0;
  font-family: Helvetica, Arial, sans-serif;
  animation: move 2.4s linear infinite;
  transform: rotate(180deg);
  color: #182a52;
}

/* #load div:nth-child(n){
  animation: move 4.4s linear infinite;
} */

@keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }

  35% {
    left: 41%;
    transform: rotate(0deg);
    opacity: 1;
  }

  65% {
    left: 59%;
    transform: rotate(0deg);
    opacity: 1;
  }

  100% {
    left: 100%;
    transform: rotate(-180deg);
    opacity: 0;
  }
}

#loadingText {
  font-family: Helvetica, Arial, sans-serif;
  color: #182a52;
  font-size: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.designationDrop {
  border-radius: 0.375rem;
  border: 1px solid #dee2e6;
  background-color: white;
}

.designationDrop label {
  padding: 16px 2.2rem 0rem 0.8rem;
  color: rgb(33, 37, 41, 0.65);
  font-size: 14px;
}

.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  /* Set background color as needed */
  font-size: 24px;
  z-index: 9999;
  /* Ensure the splash screen appears on top */
}

.loadingLogo {
  font-family: Helvetica, Arial, sans-serif;
  color: #182a52;
  font-size: 15px;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.event-table tr:nth-child(2n) td {
  /* background-color: #34dbb4 !important; */
}

.event-table tr:hover {
  /* background-color: transparent !important;
  cursor: pointer; */
}

.event-table #weekCol {
  background-color: rgba(67, 86, 116, 1) !important;
}

.event-table #totalRow {
  color: #ffffff !important;
}



/* Base skeleton styles */
/* Base skeleton styles */
.skeleton,
.skeleton-dark {
  background-color: #d5d5d5;
  /* Common background color for both */
  border-radius: 4px;
  width: 100%;
  height: 20px;
  position: relative;
  overflow: hidden;
}

/* Shimmer effect using before pseudo-element */
.skeleton::before,
.skeleton-dark::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
  transform: translateX(-100%);
  animation: shimmer 1.5s infinite;
}

/* Animation for the shimmer effect */
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

/* Optional: Different background for dark mode or darker variant */
.skeleton-dark {
  background-color: #343a3f;
  /* Darker background for skeleton-dark */
}





::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  /* background-color: #F5F5F5; */
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #445775;
}

/* Common Styles for Both Dark and Light Modes */
.eventCalender a {
  text-decoration: none;
  cursor: pointer;
}

/* Dark Mode Specific */
.eventCalender.dark-mode a {
  color: white;
}

/* Light Mode Specific */
.eventCalender.light-mode a {
  color: black !important;
}

.custom-placeholder::placeholder {
  color: #ffffff; /* White with transparency */
}

.light-placeholder::placeholder {
  color: rgba(0, 0, 0, 0.6); /* Black with transparency */
}



/* Big calendar styling  */



.rbc-time-content>.rbc-time-gutter,
.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
  background-color: var(--time-column-bg) !important;
}

.rbc-today {
  background-color: var(--time-column-bg) !important;
}

.rbc-btn-group>button {
  color: var(--color-text) !important;
}

.rbc-btn-group>.rbc-active {
  color: var(--time-column-bg) !important;
}

.rbc-header {
  height: 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px !important;
  /* min-width: 300px !important; */
}

.rbc-allday-cell {
  display: none;
}

.rbc-time-view-resources .rbc-header,
.rbc-time-view-resources .rbc-day-bg {
  background-color: transparent !important;
}

.rbc-off-range-bg {
  background: rgba(243, 243, 243, 0.578) !important;
}

.rbc-event {
  /* height: 100% !important; */
}
.rbc-addons-dnd-resizable{
  height: 100% !important;
}

.rbc-day-slot .rbc-time-slot {
  border-top: none !important;
}

.rbc-timeslot-group {
  border-bottom: none !important;
}

.rbc-current-time-indicator {
  background-color: transparent !important;
}


/* Appointment Bubbles  */

.rbc-addons-dnd-resizable {
  width: 100%;
}

/* .rbc-event {
  background-color: transparent !important;
} */
.rbc-event-label {
  display: none !important;
}

/* CSS file or styled component */
.confirmed-color {
  color: #ffffff !important;
}

.default-color {
  color: #000000 !important;
}

#appointmentTable {
  color: #000000 !important;
}

/* .appointment_bubble_status {

  height: 40px;
  width: 45px;
  color: #1B1F22 !important;
  border-radius: 50%; */
  /* Makes the container round */
  /* border: 1px solid #fff; */
  /* Add border with color and width */
  /* display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  /* Optional shadow for depth */
  /* font-size: 25px;
  text-align: center;

} */

.appointment_bubble_status {
  align-items: center;
  color: #1B1F22 !important;
  display: flex
;
  font-size: 25px;
  height: 30px;
  justify-content: center;
  text-align: center;
  width: 33px;}

/* .rbc-time-content > .rbc-day-slot {
  min-width: 500px !important;
}

.rbc-header{
  min-width: 500px !important;
} */

.rbc-time-content>.rbc-day-slot {
  /* min-width: 100% !important; */
  display: inline-block !important;
  overflow-x: auto !important;
  min-width: 300px !important;
}

.custom-rbc-event {
  /* left: calc(2 * 300px + 2 * 20px + 0px) !important; */
}

.rbc-show-more {
  color: #33DBB4 !important;
}



.visitStatus-tooltip {
  position: relative;
  cursor: pointer;
  overflow: visible;
}

.visitStatus-tooltip .visitStatus-tooltiptext {
  visibility: hidden;
  width: max-content;
  background-color: white;
  color: #000000;
  text-align: center;
  border-radius: 4px; /* Rounded corners for a better speech bubble look */
  padding: 6px 12px;

  /* Position the tooltip */
  position: absolute;
  z-index: 999;
  bottom: 115%; /* Adjust the distance from the parent */
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  /* Add shadow for depth */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.visitStatus-tooltip .visitStatus-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%; /* Position the triangle at the bottom of the tooltip */
  left: 50%;
  transform: translateX(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: white transparent transparent transparent; /* Triangle pointing downwards */
}

.visitStatus-tooltip:hover .visitStatus-tooltiptext {
  visibility: visible;
  opacity: 1;
}
.rbc-timeslot-group {
  min-height: 134px !important;
}


/* Big Calendar End  */



.statusDropdownDark .dropdown-container {
  z-index: 9;
  --rmsc-radius: .45rem;
  --rmsc-border: #495057;
  color: white;
}

.statusDropdown .dropdown-container {
  z-index: 9;
  --rmsc-radius: .45rem;
  --rmsc-border: #dee2e6;
  /* color: white; */
}

/* .rbc-date-cell, .rbc-off-range, .rbc-day-bg, .rbc-off-range-bg {
  min-width: 300px !important;
} */



.cancelled {
  --bs-table-bg: #ADADAD;

}

.present {
  --bs-table-bg: #32DBB4 !important;
  --color-text: #000000 !important;
 
}

.sold-new {
  /* --bs-table-bg: #00CC1F; */
  --bs-table-bg: #327CD9;

}
.confirmed {
  /* --bs-table-bg: #00CC1F; */
  --bs-table-bg: #DC3343;

}
.cancel {
  --bs-table-bg: #888888;
  --color-text: #000000;

}
.sold-used {
  --bs-table-bg: #327CD9;
 
}

.in-follow-up {
  --bs-table-bg: #FFA601;
  
}

#highlight-text
{
  color: black !important;
}
.views-hover:hover {
  background-color: #E9ECEF!important;
}
.views-hover-dark:hover {
  background-color: #343A3F !important;
}