/* h1, h2, h3,h4,h5,h6,p,ul,li, span, table, tr, td , th,div{
  color: white !important;
}
body,nav,.navbar, div, table, td, tr, thead, th{
  background-color: rgb(30, 30, 30) !important;
}
.App, .container, .event-portal, .dark-light{
  background-color: black !important;
} */
/* .form-wrapper{
  background-color: black !important;
} */

/* styles.css */
/* .dark-mode-text {
  color: white !important;
}

.dark-mode-bg-dark {
  background-color: black !important;
}
.dark-mode-bg-light {
  background-color: rgb(30, 30, 30) !important;
} */
/* .background1, h1, h2, h3, h4, h5, h6, /*p, ul, li,*/
/* span, table, td, tr, thead,
div:not(.modal-dialog, .modal, div.modal>*, #modalQuickAddBody, .modal-content, .offcanvas, .offcanvas div, .App, .container, .event-portal, .dark-light, .footertext, .error)
, th, body, nav, .navbar, label, .modal-content div{
  background-color: var(--color-background) !important;
  color: var(--color-text) !important;
}
  /* .offcanvas-footer p, ul, li{
    background-color: transparent !important;
  } */
/*

.App, .container, .event-portal, .dark-light, .footertext{
  background-color: var(--color-background-second) !important;
  color: var(--color-text) !important;
}
.dark-text, p, ul, li, .form-label{
  color: var(--color-text) !important;
}

.modal-content, .modal-content div{
  overflow: hidden;
  border-color: #dee2e6;
} */
.logo {
  background-color: #F8F9FA;
  padding: 5px 0 5px 10px;
  border-radius: 6px;
}

.modal-content,
.modal-header,
.modal-body,
.table,
.card {
  background-color: var(--color-background) !important;
  color: var(--color-text) !important;
}

.table {
  --bs-table-bg: var(--color-background);
}

div:not(.Toastify__toast-theme--light, .Toastify__toast-theme--light div, .client-notification,
  .selectpicker div, .error),
h1,
h2,
h3,
h4,
h5,
h6,
.navbar-brand,
.form-label,
.table,
th,
td {
  /* //color: inherit !important; */
  color: var(--color-text) !important;
}

.selectpicker div {
  /* color: black !important; */
}

.searchBtn {
  color: var(--color-text) !important;
}

#statusInactive {
  color: red !important;
}

#statusActive {
  color: #34DBB4 !important;
}

#pEventHead {
  color: #34DBB4 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#weekCol {
  background-color: rgba(67, 86, 116, 1);
  /* Use a single value for both dark and light mode if it's the same */
  color: #ffffff !important;
  /* width: 100%;
  height: 100%; */
  /* display: flex;
  align-items: center;
  justify-content: center; */
  font-size: 18px;
  margin: 0;
}

/* Adjust padding for <td> elements */
.table td {
  padding: 0rem 0.5rem;
}

/* Adjust padding for <tr> elements (if needed) */
.table tr {
  padding: 0.25rem 0.5rem;
}

.fixed-table2 {
  min-width: 1200px;
}

/* .fixed-table2 td {
  min-width: 200px;
} */

.event-report td {
  padding: 1rem;
  /* text-align: center; */
}

.event-report tr {
  margin-block: 6px;
}

.event-report th {
  width: 200px;
  border-right:
    1px solid #dee2e6 !important;
  padding-left: 2px !important;
}

.rmsc.reactMultiDark {
  --rmsc-bg: #1b1f22 !important;
  --rmsc-hover : #34DBB4 !important;
}
.rmsc.reactMultiDark .selected {
  background-color: #ced4da44 !important; /* Your desired background color */
  color: #ffffff; /* Text color */
}

.tableHover {
  background-color: white;
}
.tableHover:hover {
 background-color:#34DBB4 !important;
}

#activeStatus {
  color: #34DBB4 !important;
}

#waitingStatus {
  color: orange !important;
}

#cancelStatus {
  color:red !important;
}